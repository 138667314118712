require("./src/styles/global.css");

exports.onPreRouteUpdate = ({ location, prevLocation }) => {
  //   reset adsense ads on page change
  if (window.adsbygoogle) {
    window.adsbygoogle.length = 0;
  }
  // // mautic tracking
  // if (process.env.NODE_ENV === `production`) {
  //     if (!window.mt) {
  //         (function (w, d, t, u, n, a, m) {
  //             w['MauticTrackingObject'] = n;
  //             w[n] = w[n] || function () { (w[n].q = w[n].q || []).push(arguments) };
  //             a = d.createElement(t);
  //             m = d.getElementsByTagName(t)[0];
  //             a.async = 1;
  //             a.src = u;
  //             m.parentNode.insertBefore(a, m);
  //         })(window, document, 'script', 'https://msg.ytgraphics.com/mtc.js', 'mt');
  //     }
  //     window.mt('send', 'pageview');
  // }
};
